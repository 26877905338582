<template>
  <div>
    <AssisstantBar />
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="รายงานผลการประเมิน ครูผู้ช่วย"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2" outlined>
          <v-row>
            <v-col cols="12" md="12" class="red--text font-weight-bold">
              * รายงานผลการประเมินฯ ครูผู้ช่วย ผ่านระบบ
              ของครูผู้ช่วยที่บรรจุตั้งแต่ ปี 2566 เป็นต้นไป
              <br />
              * รอบบรรจุปี 2565 ให้จัดส่งผ่านระบบ e-office และส่งตัวจริงมายังสอศ. สำนักอำนวยการ กลุ่มงานจัดการงานบุคคล2 เท่านั้น
            </v-col>
          </v-row>
        </v-card>
       
        <v-data-table
          :headers="headers"
          :items="personnel_temporarys"
          class="elevation-1"
          :loading="loading"
          :search="search"   
          disable-pagination
        >
          <template v-slot:top>
            <div>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details               
                dense
                filled
                class="mb-2"
              />
            </div>
            
          </template>
          <template v-slot:group.header="{ items, isOpen, toggle }">
            <th colspan="22">
              <span>
                <v-icon @click="toggle"
                  >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
                </v-icon>
                บรรจุ :
                <span class="red--text">
                  {{
                    items[0].date_app_now
                      | moment("add", "543 year")
                      | moment("D MMMM YYYY")
                  }}
                </span>

                อายุงาน :
                <span class="yellow blue--text">{{
                  get_govMonth_Age(items[0].date_app_now)
                }}</span>

                <span class="red--text ml-5">ประเมิน</span>
                [ (ครั้งที่ 1) : {{ items[0].month6 | moment("D MMMM YYYY") }} ,
                (ครั้งที่ 2) : {{ items[0].month12 | moment("D MMMM YYYY") }} ,
                (ครั้งที่ 3) : {{ items[0].month18 | moment("D MMMM YYYY") }} ,
                (ครั้งที่ 4) : {{ items[0].month24 | moment("D MMMM YYYY") }}

                ]
              </span>
            </th>
          </template>

          <template v-slot:item.pt_licence="{ item }">
            <div v-if="item.pt_licence">
              <div v-if="String(item.pt_licence_file).length < 10">
                <v-chip color="error" outlined>
                  <v-icon>mdi-alert</v-icon> ไม่ได้แนบไฟล์
                </v-chip>
              </div>
              <div v-else>
                <v-chip
                  @click="viewpdflicenseteach(item.pt_licence_file)"
                  outlined
                  color="info"
                >
                  <v-icon>mdi-magnify</v-icon> {{ item.pt_licence }}
                </v-chip>
              </div>
              หมดอายุวันที่ <br />
              {{
                item.pt_enddate
                  | moment("add", "543 year")
                  | moment("D MMMM YYYY")
              }}
            </div>
            <div v-else>
              <v-chip outlined color="warning">
                <v-icon>mdi-alert</v-icon> ไม่มี/กรอกข้อมูลไม่ถูกต้อง
              </v-chip>
            </div>
          </template>

          <template v-slot:item.score_s1="{ item }">
            <span v-if="item.score_s1check === 'Pass'" class="success--text">
              <v-icon color="green">mdi-checkbox-marked-circle-outline</v-icon>
              {{ item.score_s1 }}</span
            >
            <span
              v-else-if="item.score_s1check === 'NotPass'"
              class="red--text"
            >
              <v-icon color="red">mdi-close-circle</v-icon>
              {{ item.score_s1 }}</span
            >
            <span v-else class="grey--text"> {{ item.score_s1 }}</span>
          </template>

          <template v-slot:item.score_s2="{ item }">
            <span v-if="item.score_s2check === 'Pass'" class="success--text">
              <v-icon color="green">mdi-checkbox-marked-circle-outline</v-icon>
              {{ item.score_s2 }}</span
            >
            <span
              v-else-if="item.score_s2check === 'NotPass'"
              class="red--text"
            >
              <v-icon color="red">mdi-close-circle</v-icon>
              {{ item.score_s2 }}</span
            >
            <span v-else class="grey--text"> {{ item.score_s2 }}</span>
          </template>

          <template v-slot:item.score_s3="{ item }">
            <span v-if="item.score_s3check === 'Pass'" class="success--text">
              <v-icon color="green">mdi-checkbox-marked-circle-outline</v-icon>
              {{ item.score_s3 }}</span
            >
            <span
              v-else-if="item.score_s3check === 'NotPass'"
              class="red--text"
            >
              <v-icon color="red">mdi-close-circle</v-icon>
              {{ item.score_s3 }}</span
            >
            <span v-else class="grey--text"> {{ item.score_s3 }}</span>
          </template>

          <template v-slot:item.score_s4="{ item }">
            <span v-if="item.score_s4check === 'Pass'" class="success--text">
              <v-icon color="green">mdi-checkbox-marked-circle-outline</v-icon>
              {{ item.score_s4 }}</span
            >
            <span
              v-else-if="item.score_s4check === 'NotPass'"
              class="red--text"
            >
              <v-icon color="red">mdi-close-circle</v-icon>
              {{ item.score_s4 }}</span
            >
            <span v-else class="grey--text"> {{ item.score_s4 }}</span>
          </template>

          <template v-slot:item.pertem_assistantFile="{ item }">
            <div v-if="item.pertem_assistantFile">
              <div>
                <v-btn
                  color="info"
                  icon
                  @click="viewfilePDF(item.pertem_assistantFile)"
                  ><v-icon>mdi-printer</v-icon></v-btn
                >
                <v-btn
                  color="red"
                  icon
                  @click="pertem_assistantFileCC(item.id_card)"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </div>
              <div v-if="item.pertem_assistantFileCC === 'CC'">
                <v-chip dark color="red">คำขอยกเลิกแนบไฟล์</v-chip>
              </div>
            </div>
            <div v-else>
              <v-btn
                rounded
                color="warning"
                @click="pertem_assistantFileAdd(item.id_card)"
              >
                <v-icon class="pr-3">mdi-file-plus</v-icon> แนบคำสั่ง
              </v-btn>
            </div>
          </template>

          <template v-slot:item.committee="{ item }">
            <v-btn rounded color="info" @click="commiteeAdd(item.id_card)">
              <v-icon class="pr-3">mdi-account-multiple </v-icon> กรรมการประเมิน
            </v-btn>
          </template>

          <template v-slot:item.position_name="{ item }">
            <template>
              <div>
                {{ item.position_name }}
              </div>
            </template>
          </template>

          <template v-slot:item.brith_day="{ item }">
            {{
              item.brith_day + "/" + item.brith_month + "/" + item.brith_year
            }}
            <v-chip color="#66BB6A" dark>
              {{ get_Age(item.brith_year, item.brith_month, item.brith_day) }}
            </v-chip>
          </template>

          <template v-slot:item.evaluate_s="{ item }">
            <v-chip
              color="green"
              dark
              v-if="item.report_id_card === item.id_card"
              @click.stop="evaluate_assistant(item.id_card)"
            >
              <h2>
                <v-icon>mdi-checkbox-marked-circle</v-icon> ส่งผลการประเมิน
              </h2>
            </v-chip>
            <v-chip
              v-else-if="
                item.last_time_ass === '1' ||
                  item.last_time_ass === '2' ||
                  item.last_time_ass === '3' ||
                  item.last_time_ass === '4'
              "
              color="info"
              @click.stop="evaluate_assistant(item.id_card)"
            >
              <h2>
                <v-avatar left class="green darken-4 pa-1">
                  <v-icon class="pa-1">mdi-cursor-pointer</v-icon>
                </v-avatar>

                ครั้งที่ :
                <v-avatar right class="green darken-4 pa-1">
                  {{ item.last_time_ass }}
                </v-avatar>
              </h2>
            </v-chip>
            <v-chip
              v-else
              color="warning"
              @click.stop="evaluate_assistant(item.id_card)"
            >
              <h2>
                <v-icon class="pa-1">mdi-cursor-pointer</v-icon>
                ไม่ได้เริ่มประเมิน
              </h2>
            </v-chip>
          </template>

          <template v-slot:item.prints="{ item }">
            <v-chip
              color="green"
              dark
              v-if="item.report_id_card === item.id_card"
            >
              <h2><v-icon>mdi-cube-send</v-icon> รายงานผล</h2>
            </v-chip>
            <v-chip v-else-if="item.last_time_ass === ''" color="warning">
              <h2>
                <v-icon class="pa-1">mdi-timer-sand</v-icon>
              </h2>
            </v-chip>
            <v-chip v-else color="warning">
              <h2>
                <v-icon class="pa-1">mdi-timer-sand</v-icon>
              </h2>
            </v-chip>
          </template>
          <template v-slot:item.date_app_now="{ item }">
            {{
              item.date_app_now
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}

            <div>
              <v-chip color="#F48FB1" dark>{{
                get_gov_Age(item.date_app_now)
              }}</v-chip>
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              color="yellow"
              @click.stop="personnel_temporaryEdit(item.id_card)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>

        <div class="ma-5">
          <v-chip outlined color="warning"
            ><v-icon>mdi-alert</v-icon> ไม่มี
          </v-chip>
          <span class="red--text font-weigh-bold">
            * กรุณากรอกข้อมูลใบอนุญาตประกอบวิชาชีพครู โดยงานบุคลากรกรอกข้อมูล
            หรือ ติดต่อครูในสถานศึกษาให้กรอกข้อมูลใบอนุญต</span
          >
        </div>
      </base-material-card>

      <!-- V-model evaluate_assistantdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="evaluate_assistantdialog" max-width="90%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="green"
              icon="mdi-clipboard-text"
              title="บันทึกคะแนนการประเมิน รายบุคคล"
              class="px-5 py-3"
            ></base-material-card>
            <v-card>
              <v-form ref="evaluate_assistantform" lazy-validation>
                <v-card class="pa-1">
                  <template slot="progress">
                    <v-progress-linear
                      color="deep-purple"
                      height="10"
                      indeterminate
                    ></v-progress-linear>
                  </template>

                  <v-card-title
                    ><v-icon large color="info">mdi-account-check</v-icon>
                    <h2>ข้อมูลครูผู้ช่วย ผู้รับการประเมิน</h2></v-card-title
                  >
                  <v-card-text>
                    <div class="my-4">
                      <h3>
                        สถานศึกษา :
                        {{ personnel_temporary_id_card.college_name }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ personnel_temporary_id_card.id_card }} <br />
                      ชื่อ-นามสกุล :
                      {{ personnel_temporary_id_card.frist_names }} <br />
                      วันที่บรรจุ :
                      {{ personnel_temporary_id_card.date_app }} <br />
                      คำสั่งที่บรรจุ :
                      {{ personnel_temporary_id_card.order_app_now }}
                      <h3 v-if="times_s === 1">
                        ครั้งที่ประเมิน : {{ times_s }} กำหนดวันที่
                        {{
                          personnel_temporary_id_card.month6
                            | moment("D MMMM YYYY")
                        }}
                      </h3>
                      <h3 v-else-if="times_s === 2">
                        ครั้งที่ประเมิน : {{ times_s }} กำหนดวันที่
                        {{
                          personnel_temporary_id_card.month12
                            | moment("D MMMM YYYY")
                        }}
                      </h3>
                      <h3 v-else-if="times_s === 3">
                        ครั้งที่ประเมิน : {{ times_s }} กำหนดวันที่
                        {{
                          personnel_temporary_id_card.month18
                            | moment("D MMMM YYYY")
                        }}
                      </h3>
                      <h3 v-else-if="times_s === 4">
                        ครั้งที่ประเมิน : {{ times_s }} กำหนดวันที่
                        {{
                          personnel_temporary_id_card.month24
                            | moment("D MMMM YYYY")
                        }}
                      </h3>
                    </div>
                    <div class="my-4"></div>
                  </v-card-text>

                  <v-divider class="mx-4"></v-divider>

                  <v-card-title class="text-center">
                    <h3>รายงานผลการประเมิน</h3>
                  </v-card-title>
                  <div class="text-right" v-if="checkDateAss === true">
                    <v-btn
                      v-if="times_s !== 'end'"
                      color="success"
                      elevation="4"
                      rounded
                      @click="assistant_teacher_add()"
                    >
                      <v-icon>mdi-content-save</v-icon>
                      <span>กรอกผลการประเมิน</span>
                    </v-btn>
                  </div>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers_aassistant_teacher"
                        :items="assistant_teachers"
                        class="elevation-1"
                        :loading="loading"
                        disable-pagination
                        hide-default-footer
                      >
                        <template
                          v-slot:item.assistant_teacher_datetime="{
                            item
                          }"
                        >
                          {{
                            item.assistant_teacher_datetime
                              | moment("add", "543 years")
                              | moment("D MMMM YYYY")
                          }}
                        </template>

                        <template
                          v-slot:item.assistant_teacher_score="{
                            item
                          }"
                        >
                          <v-chip
                            color="red"
                            dark
                            v-if="
                              item.assistant_teacher_times === '1' &&
                                item.assistant_teacher_score < 60
                            "
                          >
                            <h2>{{ item.assistant_teacher_score }}</h2>
                          </v-chip>
                          <v-chip
                            color="green"
                            dark
                            v-else-if="
                              item.assistant_teacher_times === '1' &&
                                item.assistant_teacher_score >= 60
                            "
                          >
                            <h2>{{ item.assistant_teacher_score }}</h2>
                          </v-chip>
                          <v-chip
                            color="red"
                            dark
                            v-else-if="
                              item.assistant_teacher_times === '2' &&
                                item.assistant_teacher_score < 60
                            "
                          >
                            <h2>{{ item.assistant_teacher_score }}</h2>
                          </v-chip>
                          <v-chip
                            color="green"
                            dark
                            v-else-if="
                              item.assistant_teacher_times === '2' &&
                                item.assistant_teacher_score >= 60
                            "
                          >
                            <h2>{{ item.assistant_teacher_score }}</h2>
                          </v-chip>
                          <v-chip
                            color="red"
                            dark
                            v-else-if="
                              item.assistant_teacher_times === '3' &&
                                item.assistant_teacher_score < 70
                            "
                          >
                            <h2>{{ item.assistant_teacher_score }}</h2>
                          </v-chip>
                          <v-chip
                            color="green"
                            dark
                            v-else-if="
                              item.assistant_teacher_times === '3' &&
                                item.assistant_teacher_score >= 70
                            "
                          >
                            <h2>{{ item.assistant_teacher_score }}</h2>
                          </v-chip>
                          <v-chip
                            color="red"
                            dark
                            v-else-if="
                              item.assistant_teacher_times === '4' &&
                                item.assistant_teacher_score < 70
                            "
                          >
                            <h2>{{ item.assistant_teacher_score }}</h2>
                          </v-chip>
                          <v-chip
                            color="green"
                            dark
                            v-else-if="
                              item.assistant_teacher_times === '4' &&
                                item.assistant_teacher_score >= 70
                            "
                          >
                            <h2>{{ item.assistant_teacher_score }}</h2>
                          </v-chip>
                        </template>

                        <template
                          v-slot:item.assistant_teacher_status="{ item }"
                        >
                          <v-icon
                            class="pa-1"
                            color="green"
                            v-if="item.assistant_teacher_status === 'confirm'"
                            >mdi-checkbox-marked-circle</v-icon
                          >

                          <v-icon class="pa-1" v-else color="warning"
                            >mdi-timer-sand</v-icon
                          >
                        </template>

                        <template v-slot:item.assistant_teacher_file="{ item }">
                          <v-btn
                            v-if="item.assistant_teacher_file"
                            large
                            icon
                            color="info"
                            @click="viewfilePDF(item.assistant_teacher_file)"
                          >
                            <v-icon>mdi-printer</v-icon>
                          </v-btn>
                        </template>

                        <template
                          v-slot:item.assistant_teacher_file_update="{
                            item
                          }"
                        >
                          <div
                            v-if="
                              String(assisstantCount) ===
                                String(item.assistant_teacher_times)
                            "
                          >
                            <v-icon
                              v-if="item.assistant_teacher_status === 'confirm'"
                              color="green"
                              large
                            >
                              mdi-cube-send
                            </v-icon>
                            <v-icon
                              v-else
                              large
                              color="red"
                              @click.stop="
                                assistant_teacherFileEdit(
                                  item.assistant_teacher_id
                                )
                              "
                            >
                              mdi-delete-circle
                            </v-icon>
                          </div>
                        </template>

                        <template
                          v-slot:item.assistant_teacher_comment="{ item }"
                        >
                          <div
                            v-if="item.assistant_teacher_status === 'confirm'"
                          >
                            <v-icon color="green" large>
                              mdi-cube-send
                            </v-icon>
                          </div>

                          <div
                            v-else-if="item.assistant_teacher_comment === 'CC'"
                          >
                            <v-icon
                              large
                              color="warning"
                              @click.stop="
                                assistant_teacherEdit(item.assistant_teacher_id)
                              "
                            >
                              mdi-pencil
                            </v-icon>
                          </div>

                          <div
                            v-else-if="
                              item.assistant_teacher_comment === 'cancel'
                            "
                          >
                            <v-chip color="red" dark>
                              <v-icon>mdi-alert</v-icon> แจ้งยกเลิก
                              รอดำเนินการ</v-chip
                            >
                          </div>

                          <div v-else>
                            <v-chip
                              color="warning"
                              @click.stop="
                                assistant_teacherCancel(
                                  item.assistant_teacher_id
                                )
                              "
                            >
                              <v-icon>
                                mdi-alert
                              </v-icon>
                              แจ้งยกเลิก</v-chip
                            >
                          </div>
                        </template>

                        <template
                          v-slot:item.assistant_teacher_ipacheck="{ item }"
                        >
                          <div>
                            <v-icon
                              color="success"
                              v-if="item.assistant_teacher_ipacheck === 'Pass'"
                              >mdi-checkbox-marked-circle-outline</v-icon
                            >
                            <v-icon
                              color="red"
                              v-else-if="
                                item.assistant_teacher_ipacheck === 'NotPass'
                              "
                              >mdi-close-circle-outline</v-icon
                            >

                            <v-icon color="grey" v-else
                              >mdi-checkbox-blank-circle-outline</v-icon
                            >
                          </div>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <span class="red--text">
                      *หากต้องการแก้ไขการประเมิน ให้กดรายงานแจ้งยกเลิก พร้อมระบุเหตุผล</span
                    >
                    <v-spacer></v-spacer>
                    <v-row>
                      <v-col cols="12" class="text-center">
                        <v-chip
                          dark
                          color="red"
                          v-if="
                            times_s === 'end' &&
                              result_assistant_teacher_reports.assistant_teacher_re_id_card !==
                                personnel_temporary_id_card.id_card
                          "
                        >
                          <h2>
                            <v-icon>mdi-alert</v-icon>
                            เมื่อกดรายงานผลการประเมินและยืนยันผลการประเมิน
                            สถานศึกษาจะไม่สามารถดำเนินการแก้ไขข้อมูลได้แล้ว
                            กรุณาตรวจสอบให้ถูกต้องก่อนกดปุ่มยืนยัน
                          </h2>
                        </v-chip>
                      </v-col>
                      <v-col cols="12" class="text-right">
                        <v-btn
                          outlined
                          color="warning"
                          @click.stop="evaluate_assistantdialog = false"
                          rounded
                        >
                          <v-icon dark>mdi-close</v-icon>ปิด
                        </v-btn>

                        <v-btn
                          v-if="
                            times_s === 'end' &&
                              result_assistant_teacher_reports.assistant_teacher_re_id_card !==
                                personnel_temporary_id_card.id_card
                          "
                          color="green"
                          @click.stop="evaluate_assistantSubmit()"
                          rounded
                        >
                          <v-icon dark>mdi-content-save</v-icon
                          >&nbsp;รายงานผลการประเมิน
                        </v-btn>
                        <!--    <v-btn
                          color="info"
                          large
                          rounded
                          v-if="
                            result_assistant_teacher_reports.assistant_teacher_re_id_card ===
                              personnel_temporary_id_card.id_card
                          "
                        >
                          <v-icon>mdi-printer</v-icon>
                          &nbsp; พิมพ์รายงาน
                        </v-btn> -->
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-card>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model assistant_teacherdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="assistant_teacherdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="green"
              icon="mdi-clipboard-text"
              title="บันทึกคะแนนประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="assistant_teacherdialogform" lazy-validation>
                <v-container grid-list-md>
                  <v-card-text>
                    <div class="my-4">
                      <h3>
                        สถานศึกษา :
                        {{ personnel_temporary_id_card.college_name }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ personnel_temporary_id_card.id_card }} <br />
                      ชื่อ-นามสกุล :
                      {{ personnel_temporary_id_card.frist_names }} <br />
                      วันที่บรรจุ :
                      {{ personnel_temporary_id_card.date_app }} <br />
                      คำสั่งที่บรรจุ :
                      {{ personnel_temporary_id_card.order_app_now }} <br />
                      <br />

                      <h3>ครั้งที่ประเมิน : {{ times_s }}</h3>
                    </div>
                    <div class="my-4"></div>
                  </v-card-text>
                  <v-layout wrap>
                    <v-flex md12 class="text-center">
                      <h3>คะแนนประเมิน</h3>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        v-model="addassistant_teacher.assistant_teacher_score"
                        type="number"
                        label="คะแนน"
                        prepend-icon="mdi-numeric"
                        outlined
                        required
                        :rules="[
                          v => !!v || '',
                          v => (!!v && v <= 100) || 'กรอกคะแนนไม่ถูกต้อง'
                        ]"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="
                              addassistant_teacher.assistant_teacher_datetime
                            "
                            label="วันที่ประเมิน"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            locale="th"
                            outlined
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="
                            addassistant_teacher.assistant_teacher_datetime
                          "
                          no-title
                          scrollable
                          locale="th"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu2 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu2.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs12>
                      แบบสรุปผลการประเมิน : PDF File
                      <v-file-input
                        v-model="documentsLinkfiles"
                        accept=".pdf"
                        name="documentsLinkfiles"
                        color="deep-purple accent-4"
                        counter
                        label="ไฟล์ .pdf"
                        placeholder="Select your files"
                        prepend-icon="mdi-paperclip"
                        outlined
                        rounded
                        :show-size="1000"
                        :rules="[v => !!v || '']"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-flex>
                  </v-layout>
                </v-container>
                <strong class="red--text"
                  >* ครั้งที่ 1 - 2 (ไม่ต่ำกว่าร้อยละ 60 จึงจะถือว่า
                  ผ่านการประเมิน)</strong
                >
                <br />
                <strong class="red--text"
                  >* ครั้งที่ 3 - 4 (ไม่ต่ำกว่าร้อยละ 70 จึงจะถือว่า
                  ผ่านการประเมิน)</strong
                >
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="assistant_teacherdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="assistant_teacherdialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model assistant_teacherEditdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="assistant_teacherEditdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="แก้ไขคะแนนประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="assistant_teacherEditform" lazy-validation>
                <v-container grid-list-md>
                  <v-card-text>
                    <div class="my-4">
                      <h3>
                        สถานศึกษา :
                        {{ personnel_temporary_id_card.college_name }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ personnel_temporary_id_card.id_card }} <br />
                      ชื่อ-นามสกุล :
                      {{ personnel_temporary_id_card.frist_names }} <br />
                      วันที่บรรจุ :
                      {{ personnel_temporary_id_card.date_app }} <br />
                      คำสั่งที่บรรจุ :
                      {{ personnel_temporary_id_card.order_app_now }} <br />
                      <br />

                      <h3>
                        ครั้งที่ประเมิน :
                        {{ assistant_teachers_edit.assistant_teacher_times }}
                      </h3>
                    </div>
                    <div class="my-4"></div>
                  </v-card-text>
                  <v-layout wrap>
                    <v-flex md12 class="text-center">
                      <h3>คะแนนประเมิน</h3>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        v-model="
                          assistant_teachers_edit.assistant_teacher_score
                        "
                        type="number"
                        label="คะแนน"
                        required
                        :rules="[
                          v => !!v || '',
                          v => (!!v && v <= 100) || 'กรอกคะแนนไม่ถูกต้อง'
                        ]"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="
                              assistant_teachers_edit.assistant_teacher_datetime
                            "
                            label="วันที่ประเมิน"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            locale="th"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="
                            assistant_teachers_edit.assistant_teacher_datetime
                          "
                          no-title
                          scrollable
                          locale="th"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu1 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu1.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-flex>

                    <v-flex xs12>
                      แบบสรุปผลการประเมิน : PDF File
                      <v-file-input
                        v-model="documentsLinkfiles"
                        accept=".pdf"
                        name="documentsLinkfiles"
                        color="deep-purple accent-4"
                        counter
                        label="ไฟล์ .pdf"
                        placeholder="Select your files"
                        prepend-icon="mdi-paperclip"
                        outlined
                        rounded
                        :show-size="1000"
                        :rules="[v => !!v || '']"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-flex>
                  </v-layout>
                </v-container>
                <strong class="red--text"
                  >* ครั้งที่ 1 - 2 (ไม่ต่ำกว่าร้อยละ 60 จึงจะถือว่า
                  ผ่านการประเมิน)</strong
                >
                <br />
                <strong class="red--text"
                  >* ครั้งที่ 3 - 4 (ไม่ต่ำกว่าร้อยละ 70 จึงจะถือว่า
                  ผ่านการประเมิน)</strong
                >
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                outlined
                @click.stop="assistant_teacherEditdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>
                <v-icon dark>mdi-close</v-icon>ปิด
              </v-btn>
              <v-btn
                color="green"
                @click.stop="assistant_teacherEditdialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model assistant_teacherFileEditdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="assistant_teacherFileEditdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="red"
              icon="mdi-clipboard-text"
              title="ยกเลิกไฟล์คะแนนประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="assistant_teacherFileEditdialogform" lazy-validation>
                <v-container grid-list-md>
                  <v-card-text>
                    <div class="my-4">
                      <h3>
                        สถานศึกษา :
                        {{ personnel_temporary_id_card.college_name }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ personnel_temporary_id_card.id_card }} <br />
                      ชื่อ-นามสกุล :
                      {{ personnel_temporary_id_card.frist_names }} <br />
                      วันที่บรรจุ :
                      {{ personnel_temporary_id_card.date_app }} <br />
                      คำสั่งที่บรรจุ :
                      {{ personnel_temporary_id_card.order_app_now }} <br />
                      <br />

                      <h3>
                        ครั้งที่ประเมิน :
                        {{ assistant_teachers_edit.assistant_teacher_times }}
                      </h3>

                      <h3>
                        ไฟล์แนบ :
                        {{ assistant_teachers_edit.assistant_teacher_file }}
                      </h3>
                    </div>
                    <div class="my-4"></div>
                  </v-card-text>
                  <v-layout wrap> </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="assistant_teacherFileEditdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="red"
                @click.stop="assistant_teacherFileEditdialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-delete-forever</v-icon>&nbsp;ลบไฟล์
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model assistant_teacherUploadFiledialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="assistant_teacherUploadFiledialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="แก้ไขไฟล์คะแนนประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form
                ref="assistant_teacherUploadFiledialogform"
                lazy-validation
              >
                <v-container grid-list-md>
                  <v-card-text>
                    <div class="my-4">
                      <h3>
                        สถานศึกษา :
                        {{ personnel_temporary_id_card.college_name }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ personnel_temporary_id_card.id_card }} <br />
                      ชื่อ-นามสกุล :
                      {{ personnel_temporary_id_card.frist_names }} <br />
                      วันที่บรรจุ :
                      {{ personnel_temporary_id_card.date_app }} <br />
                      คำสั่งที่บรรจุ :
                      {{ personnel_temporary_id_card.order_app_now }} <br />
                      <br />

                      <h3>
                        ครั้งที่ประเมิน :
                        {{ assistant_teachers_edit.assistant_teacher_times }}
                      </h3>
                    </div>
                    <div class="my-4"></div>
                  </v-card-text>
                  <v-layout wrap>
                    <v-flex md12>
                      แบบสรุปผลการประเมิน : PDF File
                      <input type="file" ref="file2" id="file2" />
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="assistant_teacherUploadFiledialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="assistant_teacherUploadFiledialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model assistant_teacherConfirmdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="assistant_teacherConfirmdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="ยืนยันผลการประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="assistant_teacherConfirmdialogform" lazy-validation>
                <v-container grid-list-md>
                  <v-card-text>
                    <div class="my-4">
                      <h3>
                        สถานศึกษา :
                        {{ personnel_temporary_id_card.college_name }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ personnel_temporary_id_card.id_card }} <br />
                      ชื่อ-นามสกุล :
                      {{ personnel_temporary_id_card.frist_names }} <br />
                      วันที่บรรจุ :
                      {{ personnel_temporary_id_card.date_app }} <br />
                      คำสั่งที่บรรจุ :
                      {{ personnel_temporary_id_card.order_app_now }} <br />
                      <h3>
                        คะแนนเฉลี่ย :
                        {{ personnel_temporary_id_card.avg_score }}
                      </h3>
                      <br />

                      <h3>
                        หากกดปุ่มยืนยันแล้วจะไม่สามารถดำเนินการแก้ไขได้
                        กรุณาตรวจสอบให้ถูกต้องก่อนกดปุ่มยืนยัน
                      </h3>
                    </div>
                    <div class="my-4"></div>
                  </v-card-text>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="assistant_teacherConfirmdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="assistant_teacherConfirmdialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;ยืนยันผลการประเมิน
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editpersonnel_temporarydialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="editpersonnel_temporarydialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="ปรับปรุงข้อมูลบุคลากร"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editpersonnel_temporaryform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <h3>ข้อมูลการเริ่มปฏิบัติหน้าที่ในสถานศึกษาปัจจุบัน</h3>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        label="คำสั่งที่ ระบุเพียง : 783/2563"
                        v-model="editpersonnel_temporary.order_app_now"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-menu
                        ref="menu3"
                        v-model="menu3"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editpersonnel_temporary.date_app_now"
                            label="วันเดือนเริ่มปฏิบัติงาน"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            locale="th"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editpersonnel_temporary.date_app_now"
                          no-title
                          scrollable
                          locale="th"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu3 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu3.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-flex>
                  </v-layout>
                </v-container>
                <strong class="red--text">* จำเป็น</strong>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="editpersonnel_temporarydialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="editpersonnel_temporarySubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pertem_assistantFiledialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="pertem_assistantFiledialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แนบคำสั่งแต่งตั้งกรรมการประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="pertem_assistantFileform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <div class="my-4">
                        <h3>
                          สถานศึกษา :
                          {{ editpersonnel_temporary.college_name }}
                        </h3>
                        รหัสบัตรประชาชน :
                        {{ editpersonnel_temporary.id_card }} <br />
                        ชื่อ-นามสกุล :
                        {{ editpersonnel_temporary.frist_names }} <br />
                        วันที่บรรจุ :
                        {{ editpersonnel_temporary.date_app }} <br />
                        คำสั่งที่บรรจุ :
                        {{ editpersonnel_temporary.order_app_now }}
                      </div>
                    </v-flex>
                    <v-flex md12>
                      <v-file-input
                        v-model="pdfFiles"
                        accept=".pdf"
                        name="pdfFiles"
                        color="deep-purple accent-4"
                        counter
                        label="ไฟล์ .pdf"
                        placeholder="Select your files"
                        prepend-icon="mdi-paperclip"
                        outlined
                        rounded
                        :show-size="1000"
                        :rules="[v => !!v || '']"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-flex>
                  </v-layout>
                </v-container>
                <strong class="red--text">* จำเป็น</strong>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="pertem_assistantFiledialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="pertem_assistantFileSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pertem_assistantFileCCdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="pertem_assistantFileCCdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="red"
              icon="mdi-clipboard-text"
              title="ขอยกเลิกแนบคำสั่งแต่งตั้งกรรมการประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="pertem_assistantFileCCform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <div class="my-4">
                        <h3>
                          สถานศึกษา :
                          {{ editpersonnel_temporary.college_name }}
                        </h3>
                        รหัสบัตรประชาชน :
                        {{ editpersonnel_temporary.id_card }} <br />
                        ชื่อ-นามสกุล :
                        {{ editpersonnel_temporary.frist_names }} <br />
                        วันที่บรรจุ :
                        {{ editpersonnel_temporary.date_app }} <br />
                        คำสั่งที่บรรจุ :
                        {{ editpersonnel_temporary.order_app_now }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green"
                @click.stop="pertem_assistantFileCCSubmit()"
                rounded
              >
                <v-icon dark>mdi-checkbox-marked-circle</v-icon
                >&nbsp;ยืนยันยกเลิก
              </v-btn>
              <v-btn
                outlined
                color="warning"
                @click.stop="pertem_assistantFileCCdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model committeedialog -->
      <v-layout row justify-center>
        <v-dialog v-model="committeedialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="เสนอชื่อคณะกรรมการประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="committeedialogform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-simple-table fixed-header height="300px">
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-center" width="40%">
                                    ชื่อ-นามสกุล
                                  </th>
                                  <th class="text-center" width="25%">
                                    ทำหน้าที่
                                  </th>
                                  <th class="text-center" width="35%">
                                    ข้อมูล
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td class="pa-2">
                                    <v-autocomplete
                                      :items="personnel_temporarys"
                                      item-text="frist_names"
                                      item-value="id_card"
                                      v-model="commiteeIdCard[0]"
                                      prepend-icon="mdi-account"
                                      dense
                                      label="ค้นหา : ชื่อ-นามสกุล"
                                      request
                                      :rules="[v => !!v || '']"
                                      rounded
                                      outlined
                                      @change="id_cardSearch1()"
                                    >
                                      <template v-slot:item="{ item }">
                                        <div class="pa-2">
                                          <div class="font-weight-black">
                                            {{ item.frist_names }}
                                          </div>

                                          <div class="divTab50">
                                            {{ item.position_name }} :
                                            {{ item.college_name }}
                                          </div>
                                        </div>
                                      </template>
                                    </v-autocomplete>
                                  </td>
                                  <td>
                                    ประธานกรรมการ
                                  </td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td class="pa-2">
                                    <v-autocomplete
                                      :items="personnel_temporarys"
                                      item-text="frist_names"
                                      item-value="id_card"
                                      v-model="commiteeIdCard[1]"
                                      prepend-icon="mdi-account"
                                      dense
                                      label="ค้นหา : ชื่อ-นามสกุล"
                                      request
                                      :rules="[v => !!v || '']"
                                      rounded
                                      outlined
                                      @change="id_cardSearch2()"
                                    >
                                      <template v-slot:item="{ item }">
                                        <div class="pa-2">
                                          <div class="font-weight-black">
                                            {{ item.frist_names }}
                                          </div>

                                          <div class="divTab50">
                                            {{ item.position_name }} :
                                            {{ item.college_name }}
                                          </div>
                                        </div>
                                      </template>
                                    </v-autocomplete>
                                  </td>
                                  <td>
                                    กรรมการ
                                  </td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td class="pa-2">
                                    <v-autocomplete
                                      :items="personnel_temporarys"
                                      item-text="frist_names"
                                      item-value="id_card"
                                      v-model="commiteeIdCard[2]"
                                      prepend-icon="mdi-account"
                                      dense
                                      label="ค้นหา : ชื่อ-นามสกุล"
                                      request
                                      :rules="[v => !!v || '']"
                                      rounded
                                      outlined
                                      @change="id_cardSearch3()"
                                    >
                                      <template v-slot:item="{ item }">
                                        <div class="pa-2">
                                          <div class="font-weight-black">
                                            {{ item.frist_names }}
                                          </div>

                                          <div class="divTab50">
                                            {{ item.position_name }} :
                                            {{ item.college_name }}
                                          </div>
                                        </div>
                                      </template>
                                    </v-autocomplete>
                                  </td>
                                  <td>กรรมการ</td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-flex>
                  </v-layout>
                </v-container>
                <strong class="red--text">* จำเป็น</strong>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="committeedialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="committeedialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pdffileviewDialog -->
      <v-layout row justify-center>
        <v-dialog v-model="pdffileviewDialog" max-width="80%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HRvecfiles/' + pdf_files"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <!-- V-model assistant_teacherCanceldialog -->
    <v-layout row justify-center>
      <v-dialog
        v-model="assistant_teacherCanceldialog"
        persistent
        max-width="60%"
      >
        <v-card class="mx-auto pa-6">
          <base-material-card
            color="green"
            icon="mdi-clipboard-text"
            title="แจ้งยกเลิกการรายงานคะแนน"
            class="px-5 py-3 text_google"
          ></base-material-card>
          <v-card-text>
            <v-form ref="assistant_teacherCanceldialogform" lazy-validation>
              <v-container grid-list-md>
                <v-card-text>
                  <div class="my-4">
                    รหัสบัตรประชาชน :
                    {{ assistant_teachers_edit.id_card }} <br />
                    ชื่อ-นามสกุล :
                    {{ assistant_teachers_edit.frist_names }} <br />
                    ครั้งที่ :
                    {{ assistant_teachers_edit.assistant_teacher_times }}
                  </div>
                  <div class="my-4"></div>
                </v-card-text>
                <v-layout wrap>
                  <v-flex md12>
                    <v-text-field
                      v-model="
                        assistant_teachers_edit.assistant_teacher_comment_detail
                      "
                      label="เนื่องจาก"
                      prepend-icon="mdi-information-outline"
                      outlined
                      required
                      :rules="[v => !!v || '']"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              fab
              small
              outlined
              @click.stop="assistant_teacherCanceldialog = false"
              rounded
            >
              <v-icon dark>mdi-close</v-icon>
            </v-btn>
            <v-btn
              color="green"
              @click.stop="assistant_teacherCanceldialogSubmit()"
              rounded
            >
              <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <v-layout row justify-center>
      <v-dialog v-model="pdflicenseteachdialog" max-width="80%">
        <v-card class="" elevation="2">
          <embed
            :src="'/HRvecLicenseTeach/' + pt_licence_files"
            width="100%"
            height="700px"
          />
        </v-card>
      </v-dialog>
    </v-layout>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import AssisstantBar from "../../components/college/assisstantBar.vue";

export default {
  data() {
    return {
      loading: true,
      pdflicenseteachdialog: false,
      ApiKey: "HRvec2021",
      valid: true,
      addpersonnel_temporarydialog: false,
      editpersonnel_temporarydialog: false,
      deletepersonnel_temporarydialog: false,
      evaluate_assistantdialog: false,
      assistant_teacherdialog: false,
      assistant_teacherEditdialog: false,
      assistant_teacherConfirmdialog: false,
      assistant_teacherFileEditdialog: false,
      assistant_teacherUploadFiledialog: false,
      pertem_assistantFiledialog: false,
      committeedialog: false,
      pertem_assistantFileCCdialog: false,
      file2: [],
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      personnel_temporarys: [],
      addpersonnel_temporary: {},
      editpersonnel_temporary: {},
      search: "",
      pagination: {},
      singleSelect: false,
      selected: [],
    
      selectedHeaders: [],
      headers: [
        { text: "สาขาวิชา", align: "left", value: "name_branch" },
        { text: "รหัสบัตรประชาชน", align: "left", value: "id_card" },
        { text: "คำนำหน้าชื่อ", align: "left", value: "title_s" },
        { text: "ชื่อ", align: "left", value: "frist_name" },
        { text: "สกุล", align: "left", value: "last_name" },
        { text: "ตำแหน่ง", align: "center", value: "position_name" },
        { text: "เลขที่ตำแหน่ง", align: "center", value: "id_position" },
        {
          text: "คำสั่งแต่งตั้งกรรมการประเมิน",
          align: "center",
          value: "pertem_assistantFile"
        },
        { text: "ใบประกอบวิชาชีพ", align: "center", value: "pt_licence" },
        { text: "การประเมิน", align: "center", value: "evaluate_s" },
        { text: "ครั้งที่ 1", align: "center", value: "score_s1" },
        { text: "ครั้งที่ 2", align: "center", value: "score_s2" },
        { text: "ครั้งที่ 3", align: "center", value: "score_s3" },
        { text: "ครั้งที่ 4", align: "center", value: "score_s4" },
        { text: "สถานะ", align: "center", value: "prints" },
        { text: "วันเดือนปีเกิด", align: "center", value: "brith_day" },
        { text: "วันเดือนปีบรรจุ", align: "center", value: "date_app_now" },
        {
          text: "วันที่ปฏิบัติหน้าที่ ณ สถานศึกษาปัจจุบัน",
          align: "center",
          value: "date_app_now"
        },
        { text: "เกษียณ", align: "center", value: "retrire_year" },
        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        }
      ],
      headers_aassistant_teacher: [
        { text: "ครั้งที่", align: "center", value: "assistant_teacher_times" },
        {
          text: "รหัสบัตรประชาชน",
          align: "left",
          value: "assistant_teacher_id_card"
        },
        {
          text: "ชื่อ-นามสกุล",
          align: "left",
          value: "frist_names"
        },
        {
          text: "วันที่ประเมิน",
          align: "center",
          value: "assistant_teacher_datetime"
        },
        { text: "คะแนน", align: "center", value: "assistant_teacher_score" },
        { text: "สถานะ", align: "center", value: "assistant_teacher_status" },
        { text: "ไฟล์แนบ", align: "center", value: "assistant_teacher_file" },
        {
          text: "รายงาน",
          align: "center",
          value: "assistant_teacher_comment"
        },

        {
          text: "สอศ.ตรวจสอบ",
          align: "center",
          value: "assistant_teacher_ipacheck"
        },
        {
          text: "หมายเหตุ",
          align: "center",
          value: "assistant_teacher_ipacomment"
        }
        /*  {
          text: "ลบรายการ/ไฟล์แนบ",
          align: "center",
          value: "assistant_teacher_file_update"
        }, */
      ],
      rang_names: [
        { text: "-", value: "-" },
        { text: "ชำนาญการ", value: "ชำนาญการ" },
        { text: "ชำนาญการพิเศษ", value: "ชำนาญการพิเศษ" },
        { text: "เชี่ยวชาญ", value: "เชี่ยวชาญ" },
        { text: "เชี่ยวชาญพิเศษ", value: "เชี่ยวชาญพิเศษ" }
      ],
      rang_levels: ["-", 1, 2, 3, 4, 5],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      college: {},
      provinces: [],
      prefectures: [],
      userstatus: [],
      man_powers: [],
      collgegs: [],
      personnel_temporarystatus: [],
      regions: [],
      region_ena: true,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      personnel_temporary_id_card: [],
      assistant_teachers: [],
      addassistant_teacher: {},
      assistant_teachers_edit: {},
      assistant_teachers_confrim: {},
      assistant_teacher_report_s: {},
      result_assistant_teacher_reports: {},
      documentsLinkfiles: null,
      commiteeIdCard: [],
      pdfFiles: null,
      Updatepersonnel_temporary: {},
      linealerts: {},
      assisstantCount: [],
      pdf_files: [],
      pdffileviewDialog: false,
      checkDateAss: [],
      massege_cc: [],
      assistant_teacherCanceldialog: false,
      pt_licence_files: ""
    };
  },
 
  async mounted() {    
    await this.personnel_temporaryQueryAll();    
  },
  methods: {
    async viewpdflicenseteach(pt_licence_file) {
      this.pt_licence_files = pt_licence_file;
      let pt_licence_fileCk = String(pt_licence_file);
      if (pt_licence_fileCk.length > 4) {
        this.pdflicenseteachdialog = true;
      } else {
        Swal.fire({
          icon: "warning",
          title: "ไม่ได้แนบไฟล์",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },

    async viewfilePDF(file) {
      this.pdf_files = file;
      this.pdffileviewDialog = true;
    },

    async pertem_assistantFileCCSubmit() {
      this.Updatepersonnel_temporary.ApiKey = this.ApiKey;
      this.Updatepersonnel_temporary.id_card = this.editpersonnel_temporary.id_card;
      this.Updatepersonnel_temporary.pertem_assistantFileCC = "CC";

      this.Updatepersonnel_temporary.mt_id_card = this.editpersonnel_temporary.id_card;
      this.Updatepersonnel_temporary.mt_committe_status = "CC";


      let result = await this.$http.post(
        "personnel_temporary.update.php",
        this.Updatepersonnel_temporary
      );

      let result_m = await this.$http.post(
        "manage_assistant_teacher.update.idcard.php",
        this.Updatepersonnel_temporary
      );


      if (result.data.status == true && result_m.data.status==true) {
        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;

        this.linealerts.ApiKey = this.ApiKey;
        this.linealerts.college_name = userSession.user_firstname;
        this.linealerts.details =
          "งานครูผู้ช่วย : ขอยกเลิกแนบไฟล์คำสั่งแต่งตั้งคณะกรรมการ ประเมินครูผู้ช่วย";
        this.linealerts.datetime = this.date_today;

        let result_line = await this.$http.post(
          "linenotify_hr.php",
          this.linealerts
        );
        

        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        await this.personnel_temporaryQueryAll();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.pertem_assistantFileCCdialog = false;
    },
    async pertem_assistantFileCC(id_card) {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: id_card
      });
      this.editpersonnel_temporary = result.data;






      this.pertem_assistantFileCCdialog = true;


    },
    async pertem_assistantFileSubmit() {
      let result = "";
      let uploaded = null;
      if (this.$refs.pertem_assistantFileform.validate()) {
        if (this.pdfFiles != "") {
          let formData = new FormData();
          let filename =
            this.editpersonnel_temporary.id_card +
            "." +
            this.time_stamp +
            "." +
            "assistantCommittee.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.pdfFiles);
          formData.append("filename", "../HRvecfiles/" + filename);
          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });         
            this.Updatepersonnel_temporary.pertem_assistantFile = filename;        
            this.Updatepersonnel_temporary.mt_committe_file = filename
          uploaded = true;
        } else {
          uploaded = false;
        }
      } else {
        upload = false;
      }
      this.Updatepersonnel_temporary.ApiKey = this.ApiKey;
      this.Updatepersonnel_temporary.id_card = this.editpersonnel_temporary.id_card;
      this.Updatepersonnel_temporary.mt_id_card = this.editpersonnel_temporary.id_card;

 



      result = await this.$http.post(
        "personnel_temporary.update.php",
        this.Updatepersonnel_temporary
      );


      let result_m = await this.$http.post(
        "manage_assistant_teacher.update.idcard.php",
        this.Updatepersonnel_temporary
      );
   


      
      if (result.data.status == true  && result_m.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        await this.personnel_temporaryQueryAll();
      } else {
        if (!uploaded && upload) {
          Swal.fire({
            icon: "warning",
            title: "แนบไฟล์ไม่ถูกต้อง",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      this.pertem_assistantFiledialog = false;
    },
    async pertem_assistantFileAdd(id_card) {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: id_card
      });
      this.editpersonnel_temporary = result.data;
      this.pertem_assistantFiledialog = true;
    },
    async commiteeAdd(id_card) {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: id_card
      });
      this.editpersonnel_temporary = result.data;
      this.committeedialog = true;
    },
    async personnel_temporaryEdit(id_card) {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: id_card
      });
      this.editpersonnel_temporary = result.data;
      this.editpersonnel_temporary.p_word = "";
      this.editpersonnel_temporarydialog = true;
    },

    async evaluate_assistant(id_card) {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: id_card
      });
      this.personnel_temporary_id_card = result.data;

      let result_assistant = await this.$http.post("assistant_teacher.php", {
        ApiKey: this.ApiKey,
        assistant_teacher_id_card: id_card
      });
      this.assistant_teachers = result_assistant.data;
      let dataas = this.assistant_teachers;
      let countas = 0;
      dataas.forEach(value => {
        countas += 1;
      });
      this.assisstantCount = countas;

      let result_assistant_teacher_report = await this.$http.post(
        "assistant_teacher_report.php",
        {
          ApiKey: this.ApiKey,
          assistant_teacher_re_id_card: id_card
        }
      );
      this.result_assistant_teacher_reports =
        result_assistant_teacher_report.data;

      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0");
      let yyyy = today.getFullYear() + 543;
      let dateNow = new Date(yyyy + "-" + mm + "-" + dd);
      let dateNows = dateNow.getTime();

      if (this.times_s == 1) {
        let dateStop = new Date(this.personnel_temporary_id_card.month6);
        let dateStops = dateStop.getTime();
        if (dateNows > dateStops) {
          this.checkDateAss = true;
        }
      } else if (this.times_s == 2) {
        let dateStop = new Date(this.personnel_temporary_id_card.month12);
        let dateStops = dateStop.getTime();
        if (dateNows > dateStops) {
          this.checkDateAss = true;
        }
      } else if (this.times_s == 3) {
        let dateStop = new Date(this.personnel_temporary_id_card.month18);
        let dateStops = dateStop.getTime();
        if (dateNows > dateStops) {
          this.checkDateAss = true;
        }
      } else if (this.times_s == 4) {
        let dateStop = new Date(this.personnel_temporary_id_card.month24);
        let dateStops = dateStop.getTime();
        if (dateNows > dateStops) {
          this.checkDateAss = true;
        }
      }

      this.evaluate_assistantdialog = true;
    },

    async assistant_teacher_add() {
      this.addassistant_teacher = {};
      this.assistant_teacherdialog = true;
    },
    async assistant_teacherFileEdit(assistant_teacher_id) {
      let result_assistant = await this.$http.post("assistant_teacher.php", {
        ApiKey: this.ApiKey,
        assistant_teacher_id: assistant_teacher_id
      });
      this.assistant_teachers_edit = result_assistant.data;
      this.assistant_teacherFileEditdialog = true;
    },
    async assistant_teacherUploadFile(assistant_teacher_id) {
      let result_assistant = await this.$http.post("assistant_teacher.php", {
        ApiKey: this.ApiKey,
        assistant_teacher_id: assistant_teacher_id
      });
      this.assistant_teachers_edit = result_assistant.data;
      this.assistant_teacherUploadFiledialog = true;
    },

    async assistant_teacherCancel(assistant_teacher_id) {
      let result_assistant = await this.$http.post("assistant_teacher.php", {
        ApiKey: this.ApiKey,
        assistant_teacher_id: assistant_teacher_id
      });
      this.assistant_teachers_edit = result_assistant.data;

      this.assistant_teacherCanceldialog = true;
      
    },

    async assistant_teacherCanceldialogSubmit() {
      if (this.$refs.assistant_teacherCanceldialogform.validate()) {
        this.assistant_teachers_edit.ApiKey = this.ApiKey;
        this.assistant_teachers_edit.assistant_teacher_comment = "cancel";
        let result = await this.$http.post(
          "assistant_teacher.update.php",
          this.assistant_teachers_edit
        );

        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          await this.evaluate_assistant(this.assistant_teachers_edit.id_card);
          this.linealerts.ApiKey = this.ApiKey;
          this.linealerts.college_name = this.assistant_teachers_edit.college_name;
          this.linealerts.details =
            "งานครูผู้ช่วย : ขอยกเลิกเสนอชื่อคณะกรรมการประเมินครูผู้ช่วย";
          this.linealerts.datetime = this.date_today;

          let result_line = await this.$http.post(
            "linenotify_hr.php",
            this.linealerts
          );
          
          
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.assistant_teacherCanceldialog = false;
      }
    },

    async assistant_teacherEdit(assistant_teacher_id) {
      let result_assistant = await this.$http.post("assistant_teacher.php", {
        ApiKey: this.ApiKey,
        assistant_teacher_id: assistant_teacher_id
      });
      this.assistant_teachers_edit = result_assistant.data;
      this.assistant_teacherEditdialog = true;
    },

    async personnel_temporaryQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          user_name: userSession.user_name,
          position_name: "ครูผู้ช่วย"
        })
        .finally(() => (this.loading = false));
      this.personnel_temporarys = result.data;
      
    },
    async assistant_teacherdialogSubmit() {
      let result = "";
      let uploaded = null;
      if (this.$refs.assistant_teacherdialogform.validate()) {
        this.addassistant_teacher.ApiKey = this.ApiKey;
        this.addassistant_teacher.assistant_teacher_college_code = this.personnel_temporary_id_card.college_code;
        this.addassistant_teacher.assistant_teacher_id_card = this.personnel_temporary_id_card.id_card;
        this.addassistant_teacher.assistant_teacher_times = this.times_s;
        if (this.documentsLinkfiles != "") {
          let formData = new FormData();
          let filename =
            this.personnel_temporary_id_card.id_card +
            "." +
            this.times_s +
            "." +
            this.time_stamp +
            "." +
            "assistant.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.documentsLinkfiles);
          formData.append("filename", "../HRvecfiles/" + filename);
          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          if (result.data.status == true) {
            this.addassistant_teacher.assistant_teacher_file = filename;
          }
          uploaded = true;
        } else {
          uploaded = false;
        }

        result = await this.$http.post(
          "assistant_teacher.insert.php",
          this.addassistant_teacher
        );
        if (result.data.status || uploaded) {
          let result = await this.$http.post("personnel_temporary.php", {
            ApiKey: this.ApiKey,
            id_card: this.personnel_temporary_id_card.id_card
          });
          this.personnel_temporary_id_card = result.data;
          let result_assistant = await this.$http.post(
            "assistant_teacher.php",
            {
              ApiKey: this.ApiKey,
              assistant_teacher_id_card: this.personnel_temporary_id_card
                .id_card
            }
          );
          this.assistant_teachers = result_assistant.data;
          let dataas = this.assistant_teachers;
          let countas = 0;
          dataas.forEach(value => {
            countas += 1;
          });
          this.assisstantCount = countas;

          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          await this.personnel_temporaryQueryAll();
        } else {
          if (!uploaded) {
            this.snackbar.icon = "mdi-close-network";
            this.snackbar.color = "red";
            this.snackbar.text = "ไฟล์ที่แนบไม่ถูกต้อง";
            this.snackbar.show = true;
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
        this.assistant_teacherdialog = false;
      }
    },
    async assistant_teacherUploadFiledialogSubmit() {
      let result = "";
      let uploaded = null;
      if (this.$refs.file2.files[0]) {
        if (this.$refs.file2.files[0].type != "") {
          let formData = new FormData();
          let filename =
            this.assistant_teachers_edit.assistant_teacher_id_card +
            "." +
            this.assistant_teachers_edit.assistant_teacher_times +
            "." +
            this.time_stamp +
            "." +
            "assistant.pdf";
          formData.append("file", this.$refs.file2.files[0]);
          formData.append("filename", "../HRvecfiles/" + filename);
          formData.append("ApiKey", this.ApiKey);
          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          if (result.data.status == true) {
            this.assistant_teachers_edit.assistant_teacher_file = filename;
          }
          uploaded = true;
        } else {
          uploaded = false;
        }
      }
      this.assistant_teachers_edit.ApiKey = this.ApiKey;
      let result_update = await this.$http.post(
        "assistant_teacher.update.php",
        this.assistant_teachers_edit
      );
      if (
        result.data.status ||
        (uploaded && result_update.data.status == true)
      ) {
        let result = await this.$http.post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          id_card: this.assistant_teachers_edit.assistant_teacher_id_card
        });
        this.personnel_temporary_id_card = result.data;
        let result_assistant = await this.$http.post("assistant_teacher.php", {
          ApiKey: this.ApiKey,
          assistant_teacher_id_card: this.assistant_teachers_edit
            .assistant_teacher_id_card
        });
        this.assistant_teachers = result_assistant.data;
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        await this.personnel_temporaryQueryAll();
      } else {
        if (!uploaded) {
          this.snackbar.icon = "mdi-close-network";
          this.snackbar.color = "red";
          this.snackbar.text = "ไฟล์ที่แนบไม่ถูกต้อง";
          this.snackbar.show = true;
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      this.assistant_teacherUploadFiledialog = false;
    },
    async assistant_teacherFileEditdialogSubmit() {
      let resultPic = await this.$http.post("deletefile.php", {
        ApiKey: this.ApiKey,
        filename:
          "../HRvecfiles/" + this.assistant_teachers_edit.assistant_teacher_file
      });

      let resultRec = await this.$http.post("assistant_teacher.delete.php", {
        ApiKey: this.ApiKey,
        assistant_teacher_id: this.assistant_teachers_edit.assistant_teacher_id
      });
      if (resultPic.data.status == true && resultRec.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการลบผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: this.personnel_temporary_id_card.id_card
      });
      this.personnel_temporary_id_card = result.data;
      let result_assistant = await this.$http.post("assistant_teacher.php", {
        ApiKey: this.ApiKey,
        assistant_teacher_id_card: this.personnel_temporary_id_card.id_card
      });
      this.assistant_teachers = result_assistant.data;
      await this.personnel_temporaryQueryAll();
      this.assistant_teacherFileEditdialog = false;
    },
    //Update
    async assistant_teacherEditdialogSubmit() {
      let uploaded;
      if (this.$refs.assistant_teacherEditform.validate()) {
        this.assistant_teachers_edit.ApiKey = this.ApiKey;
        this.assistant_teachers_edit.assistant_teacher_ipacheck = " ";
        this.assistant_teachers_edit.assistant_teacher_comment_detail = " ";
        if (this.documentsLinkfiles != "") {
          let formData = new FormData();
          let filename =
            this.personnel_temporary_id_card.id_card +
            "." +
            this.assistant_teachers_edit.assistant_teacher_times +
            "." +
            this.time_stamp +
            "." +
            "assistant.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.documentsLinkfiles);
          formData.append("filename", "../HRvecfiles/" + filename);
          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          if (result.data.status == true) {
            this.assistant_teachers_edit.assistant_teacher_file = filename;
          }
          uploaded = true;
        } else {
          uploaded = false;
        }

        this.assistant_teachers_edit.assistant_teacher_comment = "";

        let result = await this.$http.post(
          "assistant_teacher.update.php",
          this.assistant_teachers_edit
        );
        if (result.data.status == true) {
          let result = await this.$http.post("personnel_temporary.php", {
            ApiKey: this.ApiKey,
            id_card: this.personnel_temporary_id_card.id_card
          });
          this.personnel_temporary_id_card = result.data;
          let result_assistant = await this.$http.post(
            "assistant_teacher.php",
            {
              ApiKey: this.ApiKey,
              assistant_teacher_id_card: this.personnel_temporary_id_card
                .id_card
            }
          );
          this.assistant_teachers = result_assistant.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.personnel_temporaryQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.assistant_teacherEditdialog = false;
      }
    },
    async evaluate_assistantSubmit() {
      this.assistant_teacherConfirmdialog = true;
    },
    async assistant_teacherConfirmdialogSubmit() {
      this.assistant_teachers_confrim.ApiKey = this.ApiKey;
      this.assistant_teachers_confrim.assistant_teacher_id_card = this.personnel_temporary_id_card.id_card;
      this.assistant_teachers_confrim.assistant_teacher_status = "confirm";
      this.assistant_teacher_report_s.ApiKey = this.ApiKey;
      this.assistant_teacher_report_s.assistant_teacher_re_college_code = this.personnel_temporary_id_card.college_code;
      this.assistant_teacher_report_s.assistant_teacher_re_id_card = this.personnel_temporary_id_card.id_card;
      this.assistant_teacher_report_s.assistant_teacher_re_datetime = this.date_today;
      this.assistant_teacher_report_s.assistant_teacher_re_avg_score = this.personnel_temporary_id_card.avg_score;

      let result = await this.$http.post(
        "assistant_teacher.update.confirm.php",
        this.assistant_teachers_confrim
      );
      let result_assistant_teacher_report = await this.$http.post(
        "assistant_teacher_report.insert.php",
        this.assistant_teacher_report_s
      );
      if (
        result.data.status == true &&
        result_assistant_teacher_report.data.status == true
      ) {
        let result = await this.$http.post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          id_card: this.personnel_temporary_id_card.id_card
        });
        this.personnel_temporary_id_card = result.data;
        let result_assistant = await this.$http.post("assistant_teacher.php", {
          ApiKey: this.ApiKey,
          assistant_teacher_id_card: this.personnel_temporary_id_card.id_card
        });
        let result_assistant_teacher_report = await this.$http.post(
          "assistant_teacher_report.php",
          {
            ApiKey: this.ApiKey,
            assistant_teacher_re_id_card: this.personnel_temporary_id_card
              .id_card
          }
        );
        this.result_assistant_teacher_reports =
          result_assistant_teacher_report.data;
        this.assistant_teachers = result_assistant.data;
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.personnel_temporaryQueryAll();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.assistant_teacherConfirmdialog = false;
    },
    async editpersonnel_temporarySubmit() {
      if (this.$refs.editpersonnel_temporaryform.validate()) {
        this.editpersonnel_temporary.ApiKey = this.ApiKey;
        if (this.editpersonnel_temporary.p_word == "")
          delete this.editpersonnel_temporary.p_word;
        let result = await this.$http.post(
          "personnel_temporary.update.admin.php",
          this.editpersonnel_temporary
        );
        if (result.data.status == true) {
          this.personnel_temporary = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.personnel_temporaryQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editpersonnel_temporarydialog = false;
      }
    },
    date_today_cal() {
      let today = new Date();
      return today;
    },
    get_gov_Age(date_app_now) {
      let DOB = new Date(date_app_now);
      var today = today || new Date(),
        result = {
          years: 0,
          months: 0,
          days: 0,
          toString: function() {
            return (
              (this.years ? this.years + " ปี " : "") +
              (this.months ? this.months + " เดือน " : "") +
              (this.days ? this.days + " วัน" : "")
            );
          }
        };
      result.months =
        today.getFullYear() * 12 +
        (today.getMonth() + 1) -
        (DOB.getFullYear() * 12 + (DOB.getMonth() + 1));
      if (0 > (result.days = today.getDate() - DOB.getDate())) {
        var y = today.getFullYear(),
          m = today.getMonth();
        m = --m < 0 ? 11 : m;
        result.days +=
          [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][m] +
          (1 == m && y % 4 == 0 && (y % 100 > 0 || y % 400 == 0) ? 1 : 0);
        --result.months;
      }
      result.years = (result.months - (result.months % 12)) / 12;
      result.months = result.months % 12;
      return result;
    },
    row_classes(item) {
      if (
        this.get_govMonth_Age(item.date_app_now) == 6 ||
        this.get_govMonth_Age(item.date_app_now) == 12 ||
        this.get_govMonth_Age(item.date_app_now) == 18 ||
        this.get_govMonth_Age(item.date_app_now) == 24
      ) {
        return "#F0F4C3";
      }
    },
    getColor(get_govMonth_Age) {
      if (get_govMonth_Age > 0) return "info";
      else return "";
    },
    get_govMonth_Age(date_app_now) {
      let DOB = new Date(date_app_now);
      var today = today || new Date(),
        result = {
          years: 0,
          months: 0,
          days: 0,
          toString: function() {
            return (
              (this.years ? this.years + " ปี " : "") +
              (this.months ? this.months + " เดือน " : "") +
              (this.days ? this.days + " วัน" : "")
            );
          }
        };
      result.months =
        today.getFullYear() * 12 +
        (today.getMonth() + 1) -
        (DOB.getFullYear() * 12 + (DOB.getMonth() + 1));
      if (0 > (result.days = today.getDate() - DOB.getDate())) {
        var y = today.getFullYear(),
          m = today.getMonth();
        m = --m < 0 ? 11 : m;
        result.days +=
          [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][m] +
          (1 == m && y % 4 == 0 && (y % 100 > 0 || y % 400 == 0) ? 1 : 0);
        --result.months;
      }
      result.years = (result.months - (result.months % 12)) / 12;
      result.months = result.months % 12;
      return result;
    },
    get_Age(brith_year, brith_month, brith_day) {
      let today = new Date();
      let DOB = new Date(
        parseInt(brith_year) - 543 + "-" + brith_month + "-" + brith_day
      );
      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;
      let days;
      let months;
      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
        else months = totalMonths % 12;
      }
      let age = years + " ปี " + months + " เดือน " + days + " วัน";
      return age;
    }
  },
  computed: {
   
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    times_s() {
      let times = this.personnel_temporary_id_card.last_time;
      let result;
      if (times == "1") {
        result = 2;
      } else if (times == "2") {
        result = 3;
      } else if (times == "3") {
        result = 4;
      } else if (times == "4") {
        result = "end";
      } else {
        result = 1;
      }
      return result;
    },
    date_today() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      today = dd + "/" + mm + "/" + yyyy;
      return today;
    },
    time_stamp() {
      const d = new Date();
      let time = d.getTime();
      return time;
    },
    color() {
      return "blue-grey";
    }
  },
  components: { AssisstantBar }
};
</script>

<style scoped>
.text-alert {
  font-size: 10pt;
}
</style>
